import styled from "styled-components"

const StyledNotFound = styled.section`

.notfound {
    padding: calc(1.2rem + 5vh) 0;
}

 h1 {
    font-size: 75px;
    line-height: 72px;
    font-weight: 900;
    color: #000;
    max-width: 1020px;
    @media (max-width: 1080px) {
      font-size: 50px;
      line-height: 58px;
    }
    @media (max-width: 880px) {
      font-size: 41px;
      line-height: 52px;
      text-decoration: underline;
      text-underline-position: under;
      text-decoration-color: ${props => props.colorHeading};
    }
    span {
      padding-bottom: 20px;
      display: inline-block;
      position: relative;
      overflow: hidden;
      @media (max-width: 880px) {
        padding-bottom:0;
        padding-right:0;
        display: inherit;
      }
      &:nth-child(1) {
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          transform: translate(-100vw);
          height: 7px;
          background: ${props => props.colorHeading};
          transition: 0.3s all;
          animation-name: load;
          animation-duration: 1s;
          animation-iteration-count: 1;
          animation-delay: 0.5s;
          transform-box: fill-box;
          animation-fill-mode: forwards;
          transform-origin: center;
          @media (max-width: 880px) {
            content: unset;
          }
        }
      }
      &:nth-child(2) {
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          transform: translate(-100vw);
          height: 7px;
          background: ${props => props.colorHeading};
          transition: 0.3s all;
          animation-name: load;
          animation-duration: 1s;
          animation-iteration-count: 1;
          animation-delay: 1s;
          transform-box: fill-box;
          animation-fill-mode: forwards;
          transform-origin: center;
          @media (max-width: 880px) {
            content: unset;
          }
        }
      }
      &:nth-child(3) {
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          transform: translate(-100vw);
          height: 7px;
          background: ${props => props.colorHeading};
          transition: 0.3s all;
          animation-name: load;
          animation-duration: 1s;
          animation-iteration-count: 1;
          animation-delay: 1s;
          transform-box: fill-box;
          animation-fill-mode: forwards;
          transform-origin: center;
          @media (max-width: 880px) {
            content: unset;
          }
        }
      }
        }
      }
    }
  
    @keyframes load {
      0% {
        transform: translate(-100vw);
      }
      100% {
        transform: translate(0);
      }
    }

    .notfound__row {
        display: flex;
    }

    .notfound__col {
        flex:1;


        &--2 {
          flex: 2;
          @media (max-width: 600px) {
            display: none;
          }
        }

        &--3 {
            flex: 3.15;
        }
    }

.notfound__text {
    margin: 1rem 0 ;
    
    p {
        margin: 0;
    }
    ul {
        margin: 0.3rem;
        padding-left: 2rem;
        list-style-type: disc;
    }
    a {
        text-decoration: underline;
        font-weight: 500;
    }
}

    .notfound__img {
        padding-left: 1rem;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
`

export default StyledNotFound
